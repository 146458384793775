/* eslint-disable */
"use client";

import Netflix from "@/assets/costumerSVG/1.svg";
import Mercedes from "@/assets/costumerSVG/2.svg";
import Kia from "@/assets/costumerSVG/3.svg";
import Amazon from "@/assets/costumerSVG/4.svg";
import CocaCola from "@/assets/costumerSVG/5.svg";
import Play from "@/assets/costumerSVG/6.svg";
import Allianz from "@/assets/costumerSVG/7.svg";
import Saleforce from "@/assets/costumerSVG/8.svg";
import HSBC from "@/assets/costumerSVG/9.svg";
import WW from "@/assets/costumerSVG/10.svg";
import Babbel from "@/assets/costumerSVG/11.svg";
import Warner from "@/assets/costumerSVG/12.svg";
import Plume from "@/assets/costumerSVG/13.svg";
import EA from "@/assets/costumerSVG/14.svg";
import Birk from "@/assets/costumerSVG/15.svg";
import Zoom from "@/assets/costumerSVG/16.svg";
import Shopify from "@/assets/costumerSVG/17.svg";
import Decathlon from "@/assets/costumerSVG/18.svg";
import Disney from "@/assets/costumerSVG/19.svg";
import FranceTv from "@/assets/costumerSVG/20.svg";
import Chine from "@/assets/costumerSVG/21.svg";
import Kana from "@/assets/costumerSVG/22.svg";
import Bizarre from "@/assets/costumerSVG/23.svg";
import FondCale from "@/assets/costumerSVG/24.svg";
import Lucas from "@/assets/costumerSVG/25.svg";
import Marvel from "@/assets/costumerSVG/26.svg";
import Mad from "@/assets/costumerSVG/27.svg";
import TFC from "@/assets/costumerSVG/28.svg";
import Band from "@/assets/costumerSVG/29.svg";
import Deen from "@/assets/costumerSVG/30.svg";
import Koda from "@/assets/costumerSVG/31.svg";
import KZTV from "@/assets/costumerSVG/32.svg";
import TVtokyo from "@/assets/costumerSVG/33.svg";
import CN from "@/assets/costumerSVG/34.svg";
import Blizzard from "@/assets/costumerSVG/35.svg";
import Milka from "@/assets/costumerSVG/36.svg";
import Europe from "@/assets/costumerSVG/37.svg";
import PG from "@/assets/costumerSVG/38.svg";
import Google from "@/assets/costumerSVG/39.svg";
import HP from "@/assets/costumerSVG/40.svg";
import Audible from "@/assets/costumerSVG/41.svg";
import Meo from "@/assets/costumerSVG/42.svg";
import Frontline from "@/assets/costumerSVG/43.svg";
import Proximus from "@/assets/costumerSVG/44.svg";
import DollarBank from "@/assets/costumerSVG/45.svg";
import Shred from "@/assets/costumerSVG/46.svg";
import Commo from "@/assets/costumerSVG/47.svg";
import Ace from "@/assets/costumerSVG/48.svg";
import Champion from "@/assets/costumerSVG/49.svg";
import Meilleur from "@/assets/costumerSVG/50.svg";
import VSM from "@/assets/costumerSVG/51.svg";
import Clinic from "@/assets/costumerSVG/52.svg";
import Nikelo from "@/assets/costumerSVG/53.svg";
import France3 from "@/assets/costumerSVG/54.svg";
import CanalJ from "@/assets/costumerSVG/55.svg";
import Jap from "@/assets/costumerSVG/56.svg";
import Ark from "@/assets/costumerSVG/57.svg";
import Jeep from "@/assets/costumerSVG/58.svg";
// Importez tous vos fichiers SVG des logos ici
import { FC } from "react";
import Marquee from "react-fast-marquee";
import Image from "next/image";
import { useMediaQuery } from "usehooks-ts"; // Import du hook
import { Typography } from "./typography";
import { useCurrentLocale } from "../../locales/client";

// Liste des logos importés
const importedSVGs = [
  Netflix,
  Mercedes,
  Kia,
  Amazon,
  CocaCola,
  Play,
  Allianz,
  Saleforce,
  HSBC,
  WW,
  Babbel,
  Warner,
  Plume,
  EA,
  Birk,
  Zoom,
  Shopify,
  Decathlon,
  Disney,
  FranceTv,
  Chine,
  Kana,
  Bizarre,
  FondCale,
  Lucas,
  Marvel,
  Mad,
  TFC,
  Band,
  Deen,
  Koda,
  KZTV,
  TVtokyo,
  CN,
  Blizzard,
  Milka,
  Europe,
  PG,
  Google,
  HP,
  Audible,
  Meo,
  Frontline,
  Proximus,
  DollarBank,
  Shred,
  Commo,
  Ace,
  Champion,
  Meilleur,
  VSM,
  Clinic,
  Nikelo,
  France3,
  CanalJ,
  Jap,
  Ark,
  Jeep,
  
];

type Props = {};

// Composant principal
const CustomersBanner: FC<Props> = () => {
  const isLargeScreen = useMediaQuery("(min-width: 720px)");
  const lang = useCurrentLocale();

  const labels = {
    fr: "Ils ont fait confiance à nos comédiens",
    en: "They trusted our actors",
  };

  const text = labels[lang];

  return (
    <div className="flex w-screen flex-col items-center justify-center gap-14 text-center dark:text-foreground">
      <Typography variant="h3">{text}</Typography>

      {/* Conteneur des deux bandeaux */}
      <div className="relative w-full h-16 md:h-16 lg:h-20"> {/* Ajustement de la hauteur avec des breakpoints */}

    {/* Premier bandeau avec les logos */}
    <div className="absolute top-0 left-0 w-full h-full z-20">
        {/* Background du premier bandeau */}
        <div
            className="absolute inset-0 h-full bg-[#ab91db] dark:bg-[#ab91db] max-md:h-16"
            style={{ transform: "rotate(3.5deg)", transformOrigin: "left" }} // Rotation du background
        ></div>

        {/* Logos avec rotation */}
        <div
            className="relative z-20"
            style={{ transform: "rotate(3.5deg)", transformOrigin: "left" }}
        >
            <Marquee>
                {importedSVGs.map((el, index) => (
                    <Image
                        className="mx-6 size-20 max-md:size-16 lg:mx-8"
                        src={el}
                        key={index}
                        width={50}
                        height={50}
                        alt="logo"
                        priority
                    />
                ))}
            </Marquee>
        </div>
    </div>

    {/* Deuxième bandeau horizontal blanc placé directement en dessous */}
    <div className="absolute top-0 left-0 w-full h-full z-10 bg-[#8762cc] dark:bg-[#8762cc] max-md:h-16"></div> {/* Hauteur ajustée */}
</div>
    </div>
  );
};

export default CustomersBanner;
