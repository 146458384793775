/* eslint-disable */
"use client";  // Indique que c'est un composant client

import React from 'react';
import { useRouter } from 'next/navigation';

type ClientEbookButtonProps = {
  buttonText: string;  // Texte dynamique provenant des traductions
};

const ClientEbookButton: React.FC<ClientEbookButtonProps> = ({ buttonText }) => {
  const router = useRouter();

  const handleEbookClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    router.push('/ebook-ta-voix-ton-pouvoir');  // Change l'URL sans recharger la page
  };

  return (
    <button
      className="bg-primary text-white mt-texte lg:mt-10 px-4 py-2 md:px-6 md:py-3 rounded-full flex items-center justify-center text-xs md:text-sm lg:text-base"
      id="ebook-button"
      onClick={handleEbookClick}
    >
      <span className="font-bold">{buttonText}</span>  {/* Utilisation du texte traduit */}
    </button>
  );
};

export default ClientEbookButton;
