'use client';

import { ReactNode, useEffect } from 'react';
import ReCaptchaProvider from '@/utils/ReCaptchaProvider';

interface ClientProvidersProps {
  children: ReactNode;
}

const ClientProviders = ({ children }: ClientProvidersProps) => {
  useEffect(() => {
    // Récupération de la clé reCAPTCHA
    const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

    if (!siteKey) {
      console.error('reCAPTCHA site key is not defined. Please check your environment variables.');
      return;
    }

    console.log('reCAPTCHA site key:', siteKey);

    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(siteKey, { action: 'submit' })
              .then((token: string) => {
                console.log('reCAPTCHA token:', token);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'recaptchaSuccess', // Nom de l'événement personnalisé
                  recaptchaToken: token,
                });
              })
              .catch((error: unknown) => {
                console.error('Error during reCAPTCHA execution:', error);
              });
          });
        } else {
          console.warn('grecaptcha is not available.');
        }
      };
    }
  }, []);

  return (
    <ReCaptchaProvider>
      {children}
    </ReCaptchaProvider>
  );
};

export default ClientProviders;
