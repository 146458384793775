import React, { useRef, useCallback } from 'react';
import Image from 'next/image';
import Modal from 'react-modal';
import "../../style/modalStyles.css";
import { useCurrentLocale } from '../../locales/client';

export type ComedianModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  comedian: {
    nomPrenom: string;
    image: string;
    posteFr: string;
    posteEn: string;
    audios: {
      fr?: Array<{ src: string; title: string }>;
      en?: Array<{ src: string; title: string }>;
      jp?: Array<{ src: string; title: string }>;
      ng?: Array<{ src: string; title: string }>;
      es?: Array<{ src: string; title: string }>;
      de?: Array<{ src: string; title: string }>;
      it?: Array<{ src: string; title: string }>;
    };
  };
};

const ComedianModal: React.FC<ComedianModalProps> = ({ isOpen, onRequestClose, comedian }) => {
  const lang = useCurrentLocale();
  const poste = lang === "fr" ? comedian.posteFr : comedian.posteEn;

  const audioTitles = {
    fr: lang === "fr" ? "Audio français 🇫🇷" : "French audio 🇫🇷",
    en: lang === "fr" ? "Audio anglais 🇬🇧" : "English audio 🇬🇧",
    jp: lang === "fr" ? "Audio japonais 🇯🇵" : "Japanese audio 🇯🇵",
    ng: lang === "fr" ? "Audio nigérian 🇳🇬" : "Nigerian audio 🇳🇬",
    es: lang === "fr" ? "Audio espagnol 🇪🇸" : "Spanish audio 🇪🇸",
    de: lang === "fr" ? "Audio allemand 🇩🇪" : "German audio 🇩🇪",
    it: lang === "fr" ? "Audio italien 🇮🇹" : "Italian audio 🇮🇹",
  };

  const buttonText = {
    fr: "Contactez-nous",
    en: "Contact us",
  };

  const contactButtonText = buttonText[lang];

  // Liste des références audio
  const audioRefs = useRef<HTMLAudioElement[]>([]);

  // Fonction pour gérer la lecture d'un audio
  const handlePlay = (currentAudio: HTMLAudioElement) => {
    audioRefs.current.forEach((audio) => {
      if (audio && audio !== currentAudio) {
        audio.pause(); // Met en pause les autres audios
        audio.currentTime = 0; // Réinitialise à 0
      }
    });
  };

  // Enregistrer une référence audio
  const setAudioRef = useCallback((el: HTMLAudioElement | null) => {
    if (el && !audioRefs.current.includes(el)) {
      audioRefs.current.push(el);
    }
  }, []);

  // Fonction pour rendre une section audio
  const renderAudioSection = (
    title: string,
    audios: Array<{ src: string; title: string }> | undefined,
    renderContactButton: boolean = false
  ) => (
    audios &&
    audios.length > 0 && (
      <div className="audio-section">
        <div className="mb-4 flex items-center justify-between">
          <h3>{title}</h3>
          {renderContactButton && (
            <a
            href="#contact-section"
            onClick={(e) => {
              e.preventDefault(); // Empêche le comportement par défaut du lien
              const contactSection = document.querySelector("#contact-section");
              if (contactSection) {
                contactSection.scrollIntoView({ behavior: "smooth" }); // Défilement fluide vers la section
              }
              onRequestClose(); // Ferme le modal
            }}
            className="hidden rounded-lg bg-primary px-4 py-2 text-white md:block"
          >
            {contactButtonText}
          </a>          
          )}
        </div>
        <div className="audio-grid">
          {audios.map((audio, index) => (
            <div key={index} className="audio-item">
              <p>{audio.title}</p>
              <audio
                controls
                ref={setAudioRef}
                onPlay={(e) => handlePlay(e.currentTarget)} // Gérer la lecture
                aria-label={`Audio ${audio.title}`}
              >
                <source src={audio.src} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ))}
        </div>
      </div>
    )
  );

  // Génère les sections d'audio en fonction des langues disponibles
  const audioSections = Object.keys(audioTitles).map((langKey) => {
    const audios = comedian.audios[langKey as keyof typeof comedian.audios];
    return audios && audios.length > 0 ? langKey : null;
  }).filter(Boolean);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Comedian Details"
      overlayClassName="overlay-class"
      className="modal-content"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <div className="relative flex aspect-square size-[75px] items-center justify-center">
          <Image
            src={`/comediens/${comedian.image}.webp`}
            alt={comedian.nomPrenom}
            width={75}
            height={75}
            className="rounded-full border-2 border-primary object-cover"
          />
        </div>
        <div className="modal-title">
          <h2>{comedian.nomPrenom}</h2>
          <p>{poste}</p>
        </div>
        <button onClick={onRequestClose} className="close-button" aria-label="Close">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.75 14.25L14.25 21.75M14.25 14.25L21.7499 21.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M33 18C33 25.0711 33 28.6066 30.8033 30.8033C28.6066 33 25.0711 33 18 33C10.9289 33 7.3934 33 5.1967 30.8033C3 28.6066 3 25.0711 3 18C3 10.9289 3 7.3934 5.1967 5.1967C7.3934 3 10.9289 3 18 3C25.0711 3 28.6066 3 30.8033 5.1967C32.2639 6.65731 32.7533 8.70983 32.9173 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </button>
      </div>
      <div className="modal-body">
        {audioSections.map((langKey, index) =>
          renderAudioSection(
            audioTitles[langKey as keyof typeof audioTitles],
            comedian.audios[langKey as keyof typeof comedian.audios],
            index === 0 // Affiche le bouton seulement pour la première section
          )
        )}
      </div>
    </Modal>
  );
};

export default ComedianModal;
