"use client";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

const ButtonTheme = () => {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  // Attends que le composant soit monté pour activer le thème
  useEffect(() => {
    setMounted(true);
  }, []);

  // Empêche le rendu avant que le composant ne soit monté
  if (!mounted) return null;

  return (
    <button
      aria-label="Changer de thème"
      className="fixed bottom-5 left-5 z-[1000] flex size-12 items-center justify-center rounded-full border border-white/40 bg-white/80 shadow-2xl backdrop-blur-[0.5rem] transition-all hover:scale-[1.15] active:scale-105 dark:bg-gray-950"
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
    >
      {theme === "dark" ? <Sun /> : <Moon />}
    </button>
  );
};

export default ButtonTheme;
